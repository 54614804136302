import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import WaitingPage from "../pages/waiting";
import ProfilePage from "../pages/profile";
import Cognito from "../containers/Cognito";

const App: React.FC = (props) => {
  return (
    <Router>
      <Cognito>
        <Switch>
        <Route path="/profile">
            <ProfilePage />
          </Route>
          <Route path="/waiting">
            <WaitingPage />
          </Route>
          <Route path="*">
            <WaitingPage />
          </Route>
        </Switch>
      </Cognito>
    </Router>
  );
};

export default App;
