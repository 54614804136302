import Axios, { AxiosResponse } from "axios";
import { LambdaConfig } from "../config";
import { ProfileFields } from "../pages/profile";
import { cleanAndRestartOnError } from "./cognito";

export interface RequestForRedirUrlParams {
  accessToken: string;
}

export type ErrorCode =
  | "MISSING_ORGANIZATION_ERROR"
  | "EXISTENT_ORGANIZATION_ERROR"
  | "TOKEN_EXPIRED_ERROR"
  | "BAD_FORMAT_ERROR"
  | "MISSING_TOKEN_ERROR";

interface ErrorResponse {
  code?: ErrorCode;
  error?: boolean;
}
export interface RedirUrlResponse extends ErrorResponse {
  redirectUrl: string;
}

Axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 502) {
      console.error(`[axios] ${err.config.url} error 502`);
      cleanAndRestartOnError();
    }
    throw err;
  }
);

export async function requestForRedirUrl(
  params: RequestForRedirUrlParams
): Promise<RedirUrlResponse> {
  const { baseUrl, endpointRedirUrl } = LambdaConfig;
  const { accessToken } = params;

  const URI = `${baseUrl}${endpointRedirUrl}`;
  try {
    const axiosResponse: AxiosResponse<RedirUrlResponse> = await Axios.post(
      URI,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    return axiosResponse.data;
  } catch (error) {
    // FIXME: handle other error, the following works for 400 error from lambda that return data
    return error.response.data;
  }
}

export interface RequestForRegistrationParams {
  accessToken: string;
  profile: ProfileFields;
}

export interface RegistrationResponse extends ErrorResponse {
  redirectUrl: string;
}

export async function requestForRegistration(
  params: RequestForRegistrationParams
): Promise<RegistrationResponse> {
  const { baseUrl, endpointRegistration } = LambdaConfig;
  const { accessToken, profile } = params;

  const URI = `${baseUrl}${endpointRegistration}`;
  try {
    const axiosResponse: AxiosResponse<RedirUrlResponse> = await Axios.post(
      URI,
      { profile },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    return axiosResponse.data;
  } catch (error) {
    // FIXME: handle other error, the following works for 400 error from lambda that return data
    return error.response.data;
  }
}
