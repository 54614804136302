export const LambdaConfig = {

    /**
     * (remove trailing slashes)
     */
    baseUrl: "/prod",
    
    /**
     * (needed leading slash)
     */
    endpointRedirUrl: "/api/redirectUrl",

    /**
     * (needed leading slash)
     */
    endpointRegistration: "/api/registration",

};