import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Field, Formik, FormikErrors, FormikTouched, useFormikContext } from "formik";
import Page from "../../components/Page";
import { requestForRegistration } from "../../utils/lambda";
import {
  cleanAndRestartOnError,
  getToken,
  redirectToReadme,
} from "../../utils/cognito";
import { profile } from "console";

export interface Props {
  isLoggedIn?: boolean;
}

export interface ProfileFields {
  organization: string;
  role?: string;
  industry?: string;
  vehicleType?: string[];
  techStack?: string;
  hearAboutUs?: string;
  industryOther?: string;
  techStackOther?: string;
}

interface FormItemProps<T> {
  touched: FormikTouched<T>;
  errors: FormikErrors<T>;
  name: keyof T;
  label: string;
  type?: "text" | "number";
  maxlength?: number;
  as?: any;
  required?: boolean;
  size?: number;
  multiple?: boolean;
}

const FormItem: React.FunctionComponent<FormItemProps<ProfileFields>> = (
  props
) => {
  const {
    children,
    touched,
    errors,
    name,
    label,
    type = "text",
    maxlength = undefined,
    required = false,
    multiple = undefined,
    size = undefined,
    as,
  } = props;

  return (
    <div className="form-group">
      <label htmlFor={name}>
        {required && <span className="text-danger font-weight-bold">*</span>}
        {label}
      </label>
      <Field
        name={name}
        className={
          touched[name] && errors[name]
            ? "form-control is-invalid"
            : "form-control"
        }
        type={type}
        maxLength={maxlength}
        size={size}
        multiple={multiple}
        as={as}
      >
        {children}
      </Field>

      {touched[name] && errors[name] ? (
        <div className="invalid-feedback">{errors[name]}</div>
      ) : null}
    </div>
  );
};

const ProfilePage: React.FC<Props> = (props) => {
  const initialValues: ProfileFields = {
    organization: "",
    role: "",
    industry: "",
    vehicleType: [],
    techStack: "",
    hearAboutUs: "",
    industryOther: "",
    techStackOther: "",
  };

  const [serverError, setServerError] = useState("");
  return (
    <Page>
      <div className="p-4 m-2">
        <h1 className="" title="General Kenobi">Hello there!</h1>
        <p className="lead">
          Let’s start to get to know each other! This will help us improve the
          developer portal and get back to you with an even better experience.
        </p>
        <div className="pt-2">
          {serverError && (
            <div className="m-2 alert alert-danger" role="alert">
              {serverError}
            </div>
          )}
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors: FormikErrors<ProfileFields> = {};
              if (!values.organization) {
                errors.organization = "Required";
              }
              return errors;
            }}
            onSubmit={async (profile, { setSubmitting }) => {
              setServerError("");
              const accessToken = getToken();
              if (!accessToken) {
                cleanAndRestartOnError();
                return;
              }
              debugger;
              if(profile.industry !== 'Other') {
                profile = {...profile, industryOther: ''}
              } else {
                profile = {...profile, industry: profile.industryOther}
              }
              if(profile.techStack !== 'Other') {
                profile = {...profile, techStackOther: ''}
              } else {
                profile = {...profile, techStack: profile.techStackOther}
              }
              const response = await requestForRegistration({
                accessToken,
                profile,
              });
              if (response.error && response.code) {
                console.error(
                  "[profile] onSubmit() unhandled error from api endpoint registration:",
                  response.code
                );
                // FIXME: add map code to error text
                setServerError(response.code);
                setSubmitting(false);
              } else {
                redirectToReadme(response.redirectUrl);
              }
            }}

          >
            {({ isSubmitting, values, touched, errors, handleSubmit }) => {
              console.log(JSON.stringify(values))
              const commonFields = { touched, errors };
              return (
                <Form onSubmit={handleSubmit}>
                  <FormItem
                    name="organization"
                    required
                    label="Company"
                    maxlength={255}
                    {...commonFields}
                  />
                  <FormItem name="role" label="Your role" {...commonFields} />
                  <FormItem
                    name="industry"
                    label="Industry"
                    as="select"
                    {...commonFields}
                  >
                    <option value="" disabled></option>
                    <option value="Sharing">Sharing</option>
                    <option value="Rental">Rental</option>
                    <option value="Fleet Management">Fleet Management</option>
                    <option value="EV charging">EV charging</option>
                    <option value="Other">Other</option>
                  </FormItem>
                  {
                    values.industry === 'Other'? 
                      <FormItem
                        name="industryOther"
                        label="Insert your industry"
                        as="input"
                        {...commonFields}
                      />
                    : null
                  }
                  
                  <FormItem
                    name="vehicleType"
                    label="Vehicle Type"
                    as="select"
                    multiple
                    size={5}
                    {...commonFields}
                  >
                    <option value="Car">Car</option>
                    <option value="Bike">Bike</option>
                    <option value="Moped">Moped</option>
                    <option value="Kick-scooter">Kick-scooter</option>
                    <option value="Other">Other</option>
                  </FormItem>
                  <FormItem
                    name="techStack"
                    label="Your tech stack"
                    as="select"
                    {...commonFields}
                  >
                    <option value="" disabled></option>
                    <option value="Node">Node</option>
                    <option value="Python">Python</option>
                    <option value="Java">Java</option>
                    <option value="Go">Go</option>
                    <option value="Rust">Rust</option>
                    <option value="Other">Other</option>
                  </FormItem>
                  {
                    values.techStack === 'Other'? 
                      <FormItem
                        name="techStackOther"
                        label="Insert your tech stack"
                        as="input"
                        {...commonFields}
                      />
                    : null
                  }
                  <FormItem
                    name="hearAboutUs"
                    label="How did you hear about us?"
                    as="textarea"
                    maxlength={255}
                    {...commonFields}
                  />

                  <div className="form-group pt-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Page>
  );
};

export default ProfilePage;
