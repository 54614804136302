import React from "react";
import styled from 'styled-components';
import logo from '../assets/2hireDev.png'

export interface Props {
}

const Page: React.FC<Props> = (props) => {
  return (
    <DefaultContainer>
      <Navbar>
        <NavbarContent>
          <Logo src={logo}></Logo>
        </NavbarContent>
      </Navbar>
      <Content>
        {props.children}
      </Content>
    </DefaultContainer>
  );
};

const DefaultContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Navbar = styled.div`
  height: 60px;
  width: 100%;
  background-color: #083f46;
`;

const NavbarContent = styled.div`
  display: flex;
  max-width: 1100px;
  height: 100%;
  margin: auto;
  position: relative;
  padding: 8px;
`;

const Content = styled.div`
  flex: 1;
  max-width: 1100px;
  margin: auto;
  width: 100%;
  padding: 32px;
`;

const Logo = styled.img`
  height: 100%;

  object-fit: contain;
`;

export default Page;
