import Axios from "axios";
import { CognitoConfig, SelfConfig } from "../config";
import { CognitoStatus } from "../containers/Cognito";
import { encryptStringWithSHA256, getRandomString, hashToBase64url } from "./jwt";

const urlParams = new URLSearchParams(window.location.search);

/**
 * Login flow:
 * 
 * 0. (CognitoStatus.BOOT_LOADING_CODE)
 *    check if we have a valid (not expired) access token
 *      * if yes we (go to CognitoStatus.BOOT_LOADED_TOKEN) 
 *         call lambda redirectUrl and redirect to readme / or
 *          show the form
 *      * if no continue the flow
 * 
 * 1. create initial internal status in session storage to compare later
 * 
 * 2. redirect to cognito
 * 
 * 3. user register or login on cognito
 * 
 * 4. (CognitoStatus.BOOT_LOADED_CODE)
 *    cogito redirect to "back url" and we are back on our app 
 *     with a querystring containing code and state
 * 
 * 5. we check if the state is the same we saved in session
 *     * if no, error
 *     * if yes, 
 *        * we save code (lastReceivedCode) in session storage
 *        * we do a self redirection to remove parameters on query string
 * 
 * 6. (CognitoStatus.BOOT_LOADING_TOKEN)
 *     * we read code in session storage
 *     * we request tokens for code with ajax, when tokens arrive with success:
 *       * we remove code in session storage
 *       * we save the access token and expiration date
 *         in local storage (persistent)
 *       * continue the flow
 * 
 * 7. (CognitoStatus.BOOT_LOADED_TOKEN)
 *     we call the lambda redirectUrl with ajax
 *     * if we receive the redirectUrl for readme.io we do the redirect
 *        and finish 
 *     * if we don't receive the url / we receive an error profile incomplete
 * 
 * 8. we show the profile form to add missing information (organization)
 * 
 * 9. on form submit we call the lambda register with ajax and we get the 
 *    redirect url for readme.io we do the redirect and finish 
 * 
 */

/**
 * return the status for bootstrapping the app,
 * this status depend on external interaction (redirect
 * to cognito and self redirect to remove parameters on
 * url) so we need to understand where we need to continue
 * the login flow
 * 
 * we can have 3 initial status, depending on 
 * where we are in the login flow:
 * 1. we need to redirect to cognito
 * 2. we are back from cognito (url parameters available), 
 *       need a self redirect to remove url parameters
 * 3. we removed url parameters and can continue the flow
 */
export function getInitialStatus(): CognitoStatus {
    // check url parameter
    const code = urlParams.get("code");
    if (code !== null) {
        // we're coming back with a redirect from cognito
        return CognitoStatus.BOOT_LOADED_CODE;
    }

    // check session storage for code
    if (window.sessionStorage.getItem("lastReceivedCode")) {
        return CognitoStatus.BOOT_LOADING_TOKEN;
    }


    // check local storage for access token, check also expiration
    if (window.localStorage.getItem("accessToken")) {
        // FIXME: check expiration date of access token in saved exp local storage key
        return CognitoStatus.BOOT_LOADED_TOKEN;
    }



    return CognitoStatus.BOOT_LOADING_CODE;
}




export interface RedirectForCodeParams {
    codeChallenge: string;
    pkceState: string;
}

function getRedirectUrlForCode(params: RedirectForCodeParams): string {
    const { cognitoDomain, appClientId, redirectURI } = CognitoConfig;
    const { codeChallenge, pkceState } = params;
    return "https://" +
      cognitoDomain +
      "/oauth2/authorize?response_type=code&state=" +
      pkceState +
      "&client_id=" +
      appClientId +
      "&redirect_uri=" +
      redirectURI +
      "&scope=openid&code_challenge_method=S256&code_challenge=" +
      codeChallenge;
}

export function redirectForCode(params: RedirectForCodeParams): void {
    window.location.href = getRedirectUrlForCode(params);
}

export interface RequestForTokenParams {
    codeVerifier: string;
    code: string;
}

export interface TokenResponse {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    token_type: "Bearer";
}

export async function requestForTokens(params: RequestForTokenParams): Promise<TokenResponse> {
    const { domain, region, appClientId, redirectURI } = CognitoConfig;
    const { code, codeVerifier } = params;

    const URI = "https://" +
        domain +
        ".auth." +
        region +
        ".amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id=" +
        appClientId +
        "&code_verifier=" +
        codeVerifier +
        "&redirect_uri=" +
        redirectURI +
        "&code=" +
        code;
    const axiosResponse = await Axios.post(URI, {}, { headers: { "Content-Type": "application/x-www-form-urlencoded" }});
    return axiosResponse.data;
}

/**
 * return saved access token if it's not expired
 */
export function getToken(): string | undefined {
    const { accessToken, expireAccessToken } = getStorageAccessToken();
    if (expireAccessToken && accessToken) {
        if (expireAccessToken >= Date.now()) {
            return accessToken;
        }
    }
    return undefined;
}

export function saveToken(tokenResponse: TokenResponse): void {
    const expireAT = Date.now() + (tokenResponse.expires_in * 1000);
    setStorageAccessToken(tokenResponse.access_token, expireAT)
}

export function getStorageAccessToken(): { accessToken: string | null; expireAccessToken: number | null; } {
    const expire = window.localStorage.getItem("expireAccessToken")
    return {
        accessToken: window.localStorage.getItem("acessToken"),
        expireAccessToken: expire ? parseInt(expire, 10) : null,
    };
}

export function setStorageAccessToken(accessToken: string, expireAccessToken: number): void {
    window.localStorage.setItem("acessToken", accessToken);
    window.localStorage.setItem("expireAccessToken", String(expireAccessToken));
}

export function clearStorageAccessToken(): void {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("expireAccessToken");
}

export async function initInternalState(): Promise<{ pkceState: string; codeChallenge: string; }> {
    // Create random "state"
    const pkceState = getRandomString();
    window.sessionStorage.setItem("pkceState", pkceState);

    // Create PKCE code verifier
    const codeVerifier = getRandomString();
    window.sessionStorage.setItem("codeVerifier", codeVerifier);

    // Create code challenge
    const arrayHash = await encryptStringWithSHA256(codeVerifier);
    const codeChallenge = hashToBase64url(arrayHash);
    window.sessionStorage.setItem("codeChallenge", codeChallenge);

    return { pkceState, codeChallenge };
}

export function setInternalCode(code: string): void {
    window.sessionStorage.setItem("lastReceivedCode", code);
}

export function getInternalCode(): string | null {
    return window.sessionStorage.getItem("lastReceivedCode");
}

export function getInternalState(): { pkceState: string | null; codeChallenge: string | null; codeVerifier: string | null; } {
    return {
        pkceState: window.sessionStorage.getItem("pkceState"),
        codeChallenge: window.sessionStorage.getItem("codeChallenge"),
        codeVerifier: window.sessionStorage.getItem("codeVerifier"),
    }
}

export function prepareForTokenRequest(): boolean {
    const { pkceState } = getInternalState();

    const state = urlParams.get("state");
    const code = urlParams.get("code");
    
    if (state !== pkceState) {
        console.error("[cognito] prepareForTokenRequest() invalid state");
        return false;
    }

    if (!code) {
        console.error("[cognito] prepareForTokenRequest() invalid code");
        return false;
    }

    setInternalCode(code);
    return true;
}

export function redirectForPrepareTokenRequest(): void {
    window.location.href = SelfConfig.baseUrl;
}

export function cleanAndRestartOnError(): void {
    window.sessionStorage.removeItem("pkceState");
    window.sessionStorage.removeItem("codeChallenge");
    window.sessionStorage.removeItem("codeVerifier");
    window.sessionStorage.removeItem("lastReceivedCode");

    clearStorageAccessToken();

    window.location.href = SelfConfig.baseUrl;
}

export function cleanOnFinish(): void {
    window.sessionStorage.removeItem("pkceState");
    window.sessionStorage.removeItem("codeChallenge");
    window.sessionStorage.removeItem("codeVerifier");
    window.sessionStorage.removeItem("lastReceivedCode");
}

export function redirectToReadme(redirectUrl: string): void {
    window.location.href = redirectUrl;
}