import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

export interface Props {
  isLoggedIn?: boolean;
}

const WaitingPage: React.FC<Props> = (props) => {
  return (
      <FullHeightContainer>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </FullHeightContainer>
  );
};

const FullHeightContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default WaitingPage;
