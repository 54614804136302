export const CognitoConfig = {

    // https://platform-dev.auth.eu-central-1.amazoncognito.com/
    // 
    domain: "platform-dev",
    
    region: "eu-central-1",

    cognitoDomain: "auth.developer.2hire.io",

    appClientId: "5gos6gv7u6hpanubb3o4viheji",

    userPoolId: "eu-central-1_IitBRtPpv",

    /**
     * url of this page, @see also self->baseUrl ... should be the same!
     */
    redirectURI: "https://register.developer.2hire.io",
};
