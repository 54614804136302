import React, { createContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { cleanAndRestartOnError, cleanOnFinish, getInitialStatus, getInternalCode, getInternalState, getToken, initInternalState, prepareForTokenRequest, redirectForCode, redirectForPrepareTokenRequest, redirectToReadme, requestForTokens, saveToken } from "../utils/cognito";
import { requestForRedirUrl } from "../utils/lambda";

export interface CognitoState {
  status?: CognitoStatus;
  accessToken?: string;
}

export interface CognitoStateInterface extends CognitoState {
  setState(state: Partial<CognitoState>): void;
}

/**
 * BOOT_ status are set on app bootstrap
 */
export enum CognitoStatus {
  BOOT_LOADING_CODE = "BOOT_LOADING_CODE",
  // LOADING_CODE = "LOADING_CODE",
  BOOT_LOADED_CODE = "BOOT_LOADED_CODE",
  LOADED_CODE = "LOADED_CODE",
  BOOT_LOADED_TOKEN = "BOOT_LOADED_TOKEN",
  LOADED_TOKEN = "LOADED_TOKEN",
  LOADING_TOKEN = "LOADING_TOKEN",
  BOOT_LOADING_TOKEN = "BOOT_LOADING_TOKEN",
  ERROR = "ERROR",
  PROFILE_NOT_COMPLETED = "PROFILE_NOT_COMPLETED",
  AUTHENTICATED = "AUTHENTICATED",
}

export const CognitoContext = createContext<CognitoStateInterface>({
  status: CognitoStatus.ERROR,
  accessToken: "",
  setState: (state: Partial<CognitoState>) => {},
});

const CognitoProvider: React.FunctionComponent = ({ children }) => {
  /**
   * state / controls
   */
  const setState: CognitoStateInterface["setState"] = ({
    status,
    accessToken,
  }) => {
    updateState((prevState: CognitoStateInterface) => {
      const newState = { ...prevState };
      if (status !== undefined) {
        newState.status = status;
      }
      if (accessToken !== undefined) {
        newState.accessToken = accessToken;
      }
      return newState;
    });
  };

  const stateInit: CognitoStateInterface = {
    status: getInitialStatus(),
    accessToken: "",
    setState,
  };

  const [state, updateState] = useState(stateInit);

  const history = useHistory();
  const location = useLocation();
  
  const TEST_FORM = 0;

  const transition = async () => {
    console.log("[Cognito] transition() status: ", state.status, state);
    
    
    // FIXME: for developing, we go to profile page
    if (TEST_FORM) {
      console.log("location.pathname:", location.pathname );
      if (location.pathname !== "/profile") {
        //history.push("/profile")
      } 
    } else {
      switch(state.status) {
        case CognitoStatus.BOOT_LOADING_CODE:
          if (getToken()) {
            setState({ status: CognitoStatus.BOOT_LOADED_TOKEN });
          } else {
            const { codeChallenge, pkceState } = await initInternalState();
            redirectForCode({ codeChallenge, pkceState });
          }
          break;
  
        case CognitoStatus.BOOT_LOADED_CODE:
          if (prepareForTokenRequest()) {
            redirectForPrepareTokenRequest();
          } else {
            // fixme: clean up internal state and start again from
            // FIXME: show  error and link that clear query parameters from cognito
            //         (just redirect to self.baseurl)
            cleanAndRestartOnError();
          }
          break;
  
        case CognitoStatus.BOOT_LOADING_TOKEN:
          const internalCode = getInternalCode();
          const { codeVerifier } = getInternalState();
          if (!internalCode || !codeVerifier) {
            // FIXME: better error visualization;
            throw new Error("invalid internal state #1");
          }
          const resToken = await requestForTokens({ code: internalCode, codeVerifier });
          saveToken(resToken);
          cleanOnFinish();
          setState({ status: CognitoStatus.BOOT_LOADED_TOKEN });
          break;
  
        case CognitoStatus.BOOT_LOADED_TOKEN:
          const accessToken = getToken();
          if (!accessToken) {
            throw new Error("invalid internal state #2");
          }
          const resRedirUrl = await requestForRedirUrl({ accessToken });
          if (resRedirUrl.redirectUrl) {
            redirectToReadme(resRedirUrl.redirectUrl);
          } else if (resRedirUrl.error) {
            switch (resRedirUrl.code) {
              case "MISSING_ORGANIZATION_ERROR":
                setState({ status: CognitoStatus.PROFILE_NOT_COMPLETED });
                break;
              case "TOKEN_EXPIRED_ERROR":
                cleanAndRestartOnError();
                break;
              default:
                console.error("[Cognito] transition() unhandled error on api endpoint redirUrl:", resRedirUrl.code);
                break;
            }
          }
          break;
        case CognitoStatus.PROFILE_NOT_COMPLETED:
          if (location.pathname !== "/profile") {
            history.push("/profile")
          }  
          break;
      }
    }
    
    
  };

  // run state machine
  // FIXME: maybe add dependency to prevent not needed execution
  useEffect(() => {
    transition();
  });

  return (
    <CognitoContext.Provider value={state}>{children}</CognitoContext.Provider>
  );
};

const Cognito: React.FunctionComponent = ({ children }) => {
  return <CognitoProvider>{children}</CognitoProvider>;
};

export default Cognito;
